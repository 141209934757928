
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .vertical-form-table--border {
        ::v-deep {
            .vertical-table-row {
                min-height: 54px;
                &__term,
                &__content {
                    position: relative;
                    &::before {
                        content: "";
                        position: absolute;
                        top: 50%;
                        -webkit-transform: translate(0, -50%);
                        -ms-transform: translate(0, -50%);
                        transform: translate(0, -50%);
                        display: block;
                        width: 1px;
                        height: 100%;
                        min-height: 54px;
                        background-color: var(--border-color);
                    }
                }
                &__term {
                    text-align: center;
                    &::before {
                        right: 0;
                    }
                }
                &__content {
                    &::before {
                        left: -1px;
                    }
                }
            }
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
